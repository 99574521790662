import { User } from 'app/types/user.types';

export enum LabelType {
  VERIFIED = 'verified',
  VERIFIED_HAS_BEEN_EDITED = 'verified_has_been_edited',
  VERIFIED_EXPIRE_SOON = 'verified_expire_soon',
  APPROVAL_STATUS_PENDING = 'approval_status_pending',
  APPROVAL_STATUS_REJECTED = 'approval_status_rejected',
  APPROVAL_STATUS_APPROVED = 'approval_status_approved',
  APPROVAL_STATUS_TO_RENEW = 'approval_status_to_renew',
  TO_UPDATE_FLAGGED = 'to_update_flagged',
  TO_UPDATE_OUTDATED = 'to_update_outdated',
  ARCHIVED = 'archived',
  FLAGGED = 'flagged',
  POPULAR = 'popular',
}

export interface Label {
  id: number;
  type: LabelType;
  reason: string;
  by: User;
  expire_at: Date;
  created_at: Date;
}
