import { Chip } from 'app/components/chip/chip.types';
import {
  Workflow,
  PrimeFileNode,
} from 'app/types';
import { MenuItem } from 'primeng/api';

export enum Filter {
  ALL = 'All',
  TO_APPROVE = 'To approve',
  TO_UPDATE = 'To update',
  I_REQUESTED = 'Approval status',
}

export interface Todo {
  /** BreadcrumbIcon: icon used for the breadcrumb and route to redirect on click */
  breadcrumbIcon: MenuItem;

  /** BreadcrumPath: string showing current path */
  breadcrumbPath: MenuItem[];

  /** Chips: list of chips displayed in the the page */
  chips: { [key: string]: Chip };

  /** DynamicClass: special class to extend or reduce the tables container */
  dynamicClass: string;

  /** FilesIRequested: list of files you've requested approval for */
  filesIRequested: PrimeFileNode[];

  /** FilesToApprove: list of files waiting for approval */
  filesToApprove: PrimeFileNode[];

  /** FilesToUpdate: list of files rejected */
  filesToUpdate: PrimeFileNode[];

  /** FilteredOn: filter value to know the tables to display */
  filteredOn: Filter;

  /** IsFooterDisplayed: boolean indicator for properties footer */
  isFooterDisplayed: boolean;

  /** Workflows: map to have the workflow of each file */
  workflows: Map<string, Workflow>;
}
