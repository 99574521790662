import { Injectable } from '@angular/core';
import {
  FileResponse,
  FilesResponse,
  LabelType,
  Workflow,
  WorkflowResponse,
  WorkflowsResponse,
} from 'app/types';
import { Observable } from 'rxjs';
import { TrinityService } from 'app/services/trinity/trinity.service';

interface SetTagsOptions {
  /** The ID of the file to set the tags for. */
  file_id: string;

  /** The tags to set (set to empty array to remove all tags). */
  ids: number[];
}

interface SetLabelsOptions {
  /** The ID of the file to set the labels for. */
  file_id: string;

  /** The labels to set (set to empty array to remove all labels). */
  labels: LabelType[];
}

@Injectable({
  providedIn: 'root',
})
export class FileService {
  private readonly endpoint: string = '/file';

  constructor(private trinityService: TrinityService) {}

  setTags(options: SetTagsOptions): Observable<FileResponse> {
    return this.trinityService.put<FileResponse>(
      `${this.endpoint}/tags/${options.file_id}`,
      {
        body: {
          tag_ids: options.ids,
        },
        authorized: true,
      },
    );
  }

  removeTags = (file_id: string) => this.setTags({ file_id, ids: [] });

  getFlaggedDocuments(): Observable<FilesResponse> {
    return this.trinityService.get<FilesResponse>(`${this.endpoint}/flagged`, {
      authorized: true,
    });
  }

  flagDocument(
    file_id: string,
    reasonToFlag: string,
  ): Observable<FileResponse> {
    return this.trinityService.put<FileResponse>(
      `${this.endpoint}/flag/${file_id}`,
      {
        body: { reason: reasonToFlag },
        authorized: true,
      },
    );
  }

  unflagDocument(file_id: string): Observable<FileResponse> {
    return this.trinityService.delete<FileResponse>(
      `${this.endpoint}/flag/${file_id}`,
      {
        authorized: true,
      },
    );
  }

  updateFile(file_id: string, file: any): Observable<FileResponse> {
    return this.trinityService.put<FileResponse>(
      `${this.endpoint}/${file_id}`,
      {
        body: file,
        authorized: true,
      },
    );
  }

  setConfidentiality(
    file_id: string,
    confidentiality: string,
  ): Observable<FileResponse> {
    return this.trinityService.put<FileResponse>(
      `${this.endpoint}/confidentiality/${file_id}`,
      {
        body: { confidentiality: confidentiality },
        authorized: true,
      },
    );
  }

  get(file_id: string): Observable<FileResponse> {
    return this.trinityService.get<FileResponse>(`/drive/file/${file_id}`, {
      authorized: true,
    });
  }

  getWorkflowFiles(state: string): Observable<WorkflowsResponse> {
    return this.trinityService.get<WorkflowsResponse>(`/workflow`, {
      params: {
        state: state,
      },
      authorized: true,
    });
  }

  getWorkflow(file_id: string): Observable<WorkflowResponse> {
    return this.trinityService.get<WorkflowResponse>(
      `${this.endpoint}/workflow/${file_id}`,
      {
        authorized: true,
      },
    );
  }

  requestedByMe(offset: number = 0) {
    return this.trinityService.get<FilesResponse>(
      `${this.endpoint}/requested`,
      {
        params: {
          limit: 50,
          offset: offset,
          verified_within_days: 7,
        },
        authorized: true,
      },
    );
  }

  setFileAsVerified(file_id: string): Observable<FileResponse> {
    return this.trinityService.post<FileResponse>(
      `${this.endpoint}/verify/${file_id}`,
      {
        authorized: true,
      },
    );
  }

  setFileAsApproved(
    file_id: string,
    confidentiality: string,
    expire_at: string | null,
  ): Observable<Workflow> {
    const body = expire_at
      ? { confidentiality: confidentiality, expire_at: expire_at }
      : { confidentiality: confidentiality };
    return this.trinityService.post<Workflow>(
      `${this.endpoint}/approve/${file_id}`,
      {
        body: body,
        authorized: true,
      },
    );
  }

  setFileAsRejected(file_id: string): Observable<FileResponse> {
    return this.trinityService.post<FileResponse>(
      `${this.endpoint}/reject/${file_id}`,
      {
        authorized: true,
      },
    );
  }

  unlockFile(file_id: string): Observable<FileResponse> {
    return this.trinityService.delete<FileResponse>(
      `${this.endpoint}/lock/${file_id}`,
      {
        authorized: true,
      },
    );
  }
}
