import { MenuItem } from 'primeng/api';
import {
  People,
  VerifiedVersion,
} from 'app/types';

export enum Heartbeat {
  APP_HEARTBEAT = 'PRESENCEDOC_APP_HEARTBEAT',
  EXTENSION_HEARTBEAT = 'PRESENCEDOC_EXTENSION_HEARTBEAT',
  EXTENSION_DOCUMENT = 'PRESENCEDOC_EXTENSION_DOCUMENT',
}

export enum VersioningOptions {
  DRAFT = 'Duplicate file as Version draft',
  DRAFT_FROM_FILE = 'Add version from existing file',
}

export enum Status {
  FILLED = 'FILLED',
  MY_DRIVE = 'MY_DRIVE',
  FILE_NOT_WATCHED = 'FILE_NOT_WATCHED',
  LOADING = 'LOADING',
  UNKNOWN = 'UNKNOWN',
}

export enum Workflow {
  APPROVED = 'APPROVED',
  NONE = 'NONE',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export interface Dialogs {
  description: boolean;
  properties: boolean;
  rating: boolean;
  workflow: boolean;
  flag: boolean;
  versioning: boolean;
}

export type DialogsKey = keyof Dialogs;

export interface SidePanel {
  connectedUser?: People;
  creatingDraft: boolean;
  documentConfidentialities: { name: string }[];
  documentConfidentiality: string;
  documentContributors: string[];
  documentCreator: string;
  documentDescription: string;
  documentId: string;
  documentLabels: any[];
  documentLanguage: string;
  documentName: string;
  documentTags: { label: string; source: string }[];
  documentIsVerified: boolean;
  suggestedTags: any[];
  documentType: string;
  documentTypes: { name: string }[];
  documentHasVersioning: boolean;
  documentVersioningTypes: { name: string }[];
  documentHistory: VerifiedVersion | undefined;
  documentHistoryHasADraft: boolean;
  documentIsDraft: boolean;
  documentRating: number;
  documentRatings: number[];
  languages: { name: string }[];
  likedByMe: boolean;
  likesCount: number;
  selectedExpirationDate: Date | null;
  openDialogs: Dialogs;
  selectedApprovalConfidentiality: { name: string };
  selectedDocumentConfidentiality: { name: string };
  selectedDocumentDescription: string;
  selectedDocumentTags: any[];
  selectedDocumentType: { name: string };
  selectedDocumentVersioningType: { name: string };
  selectedDocumentVersioningDriveUrl: string;
  selectedLanguage: { name: string };
  selectedRating: number;
  splitButtonItems: MenuItem[];
  status: Status;
  childrenStatus: { [key: string]: Status };
  userCurrentRating: number;
  userIsReaderOrCommentator: boolean;
  userRelatedToWorkflow: boolean;
  verifiedHasBeenModified: boolean;
  workflowState: Workflow;
  unflagDocChecked: boolean;
  reasonToFlag: string;
  fileLocked: boolean;
}
