<div class="body-content">
  <div class="path-row">
    @for (chip of chipsData; track chip.label; let index = $index) {
      <app-chip
        [label]="chip.label"
        [icon]="chip.icon"
        [isActive]="chip.isActive"
        (mousedown)="selectChip(index)"
      >
      </app-chip>
    }
  </div>
  @if (selectedChip === 'Doc') {
    <div class="filters-row">
      <p-multiSelect
        [options]="tags"
        [(ngModel)]="selectedTags"
        (onChange)="applyFilters()"
        placeholder="Select tags"
        optionLabel="tag.label"
        display="chip"
        class="multi-select"
      />
      <p-multiSelect
        [options]="creators"
        [(ngModel)]="selectedCreators"
        (onChange)="applyFilters()"
        placeholder="Select contributors"
        optionLabel="fullname"
        class="multi-select"
      >
        <ng-template let-creator pTemplate="item">
          <div class="multi-select-row">
            <p-avatar
              image="{{ creator.photo_uri }}"
              styleClass="mr-2"
              size="normal"
              shape="circle"
              class="creator-avatar"
            ></p-avatar>
            <div>{{ creator.fullname }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
      <p-multiSelect
        [options]="labels"
        [(ngModel)]="selectedLabels"
        (onChange)="applyFilters()"
        placeholder="Select labels"
        optionLabel="type"
        class="multi-select"
      />
      <p-multiSelect
        [options]="formats"
        [(ngModel)]="selectedFormats"
        (onChange)="applyFilters()"
        placeholder="Select formats"
        optionLabel="mime_type"
        class="multi-select"
      >
        <ng-template let-file pTemplate="item">
          <div class="multi-select-row">
            <img
              src="{{ file.icon_link }}"
              alt="avatar"
              class="result-icon"
              class="file-icon"
            />
            <div>{{ getShortFormat(file.mime_type) }}</div>
          </div>
        </ng-template>
      </p-multiSelect>
    </div>
  }
  @if (waitingForData) {
    <div class="flex-center">
      <p-progressSpinner></p-progressSpinner>
    </div>
  } @else {
    @if (selectedChip === 'Doc') {
      <div class="scroll-y">
        <app-ng-table [documents]="files"></app-ng-table>
      </div>
    } @else {
      <div class="scroll-y">
        <app-ng-table
          [peoples]="peoples"
          mode="peoples"
          [canSelect]="false"
        ></app-ng-table>
      </div>
    }
  }
</div>
@if (isFooterDisplayed) {
  <div class="body-content-footer">
    <app-footer
      [selectedFiles]="selectedFiles"
      [properties]="true"
      [request]="true"
    ></app-footer>
  </div>
}
