import { Injectable } from '@angular/core';
import { TrinityService } from 'app/services/trinity/trinity.service';
import { Observable } from 'rxjs';
import {
  BulkResponse,
  UpdateFilePropertiesOptions,
  UpdateFilesConfidentialityOptions,
  UpdateFilesTagsOptions,
} from 'app/types/bulk.types';

@Injectable({
  providedIn: 'root',
})
export class BulkService {
  constructor(private trinityService: TrinityService) {}

  private readonly endpointBulk: string = '/bulk/workflow';
  private readonly endpointFiles: string = '/bulk/files';

  approveFileWorkflows(
    ids: string[],
    confidentiality: string,
    expire_at: string | null,
  ): Observable<BulkResponse<File, string>> {
    const body: { [id: string]: unknown } = {
      file_ids: ids,
      confidentiality: confidentiality,
    };
    if (expire_at) {
      body['expire_at'] = expire_at;
    }
    return this.trinityService.post<BulkResponse<File, string>>(
      `${this.endpointBulk}/approve`,
      {
        body: body,
        authorized: true,
      },
    );
  }

  rejectFileWorkflows(
    ids: string[],
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.post<BulkResponse<File, string>>(
      `${this.endpointBulk}/reject`,
      {
        body: {
          file_ids: ids,
          confidentiality: 'internal', //todo: value should be given through a modal
        },
        authorized: true,
      },
    );
  }

  requestFileVerification(
    ids: string[],
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.post<BulkResponse<File, string>>(
      `${this.endpointBulk}/request`,
      {
        body: {
          file_ids: ids,
          type: 'verify',
        },
        authorized: true,
      },
    );
  }

  updateFilesProperties(
    filesProperties: UpdateFilePropertiesOptions,
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/properties`,
      {
        body: filesProperties,
        authorized: true,
      },
    );
  }

  updateFilesConfidentiality(
    filesConfidentiality: UpdateFilesConfidentialityOptions,
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/confidentiality`,
      {
        body: filesConfidentiality,
        authorized: true,
      },
    );
  }

  updateFileTags(
    filesTags: UpdateFilesTagsOptions,
  ): Observable<BulkResponse<File, string>> {
    return this.trinityService.put<BulkResponse<File, string>>(
      `${this.endpointFiles}/tags`,
      {
        body: filesTags,
        authorized: true,
      },
    );
  }
}
