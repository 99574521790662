import { Component, OnInit } from '@angular/core';
import {
  BulkResponse,
  Contribution,
  People,
  PrimeFileNode,
  File,
  FileDatamart,
  Tag,
  Label,
} from 'app/types';
import { EventManager } from 'app/services/events/events.service';
import { GdrivesService } from 'app/services/gdrives/gdrives.service';

@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
})
export class SearchResultsComponent implements OnInit {
  readonly googleFolderMimeType: string = 'application/vnd.google-apps.folder';
  tags: Array<Tag> = new Array<Tag>();
  labelsMap: Map<string, Label> = new Map<string, Label>();
  labels: Array<Label> = new Array<Label>();
  creatorsMap: Map<string, People> = new Map<string, People>();
  creators: Array<People> = new Array<People>();
  formatsMap: Map<string, File> = new Map<string, File>();
  formats: Array<File> = new Array<File>();
  selectedTags: Array<Tag> = new Array<Tag>();
  selectedLabels: Array<Label> = new Array<Label>();
  selectedCreators: Array<People> = new Array<People>();
  selectedFormats: Array<File> = new Array<File>();
  allResults: Array<File | People | Contribution> = new Array<
    File | People
  >();
  allFiles: Array<File> = new Array<File>();
  files: Array<PrimeFileNode> = new Array<PrimeFileNode>();
  peoples: Array<Contribution> = new Array<Contribution>();
  chipsData = [
    {
      label: 'Docs',
      icon: 'pi-check',
      isActive: true,
      filter: 'Doc',
      badgeValue: 0,
    },
    {
      label: 'People',
      icon: '',
      isActive: false,
      filter: 'People',
      badgeValue: 0,
    },
  ];
  selectedChip: string = 'Doc';
  waitingForData: boolean = true;
  isFooterDisplayed: boolean = false;

  /** Files selected in the table. */
  selectedFiles: PrimeFileNode[] = [];

  constructor(private gdriveService: GdrivesService) {}

  ngOnInit(): void {
    EventManager.on('tableRefreshContent', ((event: CustomEvent) => {
      const datamart = event.detail.datamart.processed as PrimeFileNode[];
      const tags = event.detail.tags as BulkResponse<File, string>;

      this.files = this.files.map((item: PrimeFileNode) => {
        if (tags) {
          const newTagsItem = tags.processed.find(
            (newItem) => newItem.id === item.id,
          );
          if (newTagsItem?.id) {
            item.tags = Object.assign(
              item.tags ?? {},
              newTagsItem.tags ?? {},
            ) as Tag[];
          }
        }

        if (datamart) {
          const newDataMartItem = datamart.find(
            (newItem) => newItem.id === item.id,
          );

          if (newDataMartItem?.id) {
            item.datamart = Object.assign(
              item.datamart ?? {},
              newDataMartItem.datamart ?? {},
            ) as FileDatamart;
          }
        }

        return item;
      });
    }) as EventListener);

    this.allResults = this.gdriveService.getSearchResults();
    if (this.allResults.length > 0) {
      this.allFiles = this.allResults.filter(
        (result: File | People | Contribution) => {
          return (result as File)?.mime_type;
        },
      ) as Array<File>;
      this.files = this.allResults.filter(
        (result: File | People | Contribution) => {
          return (result as File)?.mime_type;
        },
      ) as Array<PrimeFileNode>;
      this.peoples = this.allResults.filter(
        (result: File | People | Contribution) => {
          (result as Contribution)?.count > 0;
        },
      ) as Array<Contribution>;
      this.waitingForData = false;
    } else {
      this.waitingForData = true;
      setTimeout(() => {
        this.waitingForData = false;
      }, 3000);
    }
    EventManager.on('searchResults', () => {
      this.allResults = this.gdriveService.getSearchResults();

      this.allFiles = this.allResults.filter(
        (result: File | People | Contribution) => {
          return (result as File)?.mime_type;
        },
      ) as Array<File>;

      this.files = this.allResults.filter(
        (result: File | People | Contribution) => {
          return (result as File)?.mime_type;
        },
      ) as Array<PrimeFileNode>;

      this.peoples = this.allResults.filter(
        (result: File | People | Contribution) => {
          return (result as Contribution)?.count > 0;
        },
      ) as Array<Contribution>;
      this.peoples.sort((a, b) => {
        return b.count - a .count;
      });
      this.setLabels(this.allFiles);
      this.setCreators(this.allFiles);
      this.setFormats(this.allFiles);
      this.setTags(this.allFiles);
      this.waitingForData = false;
    });

    EventManager.on('tableSelectedFiles', () => {
      this.selectedFiles = this.gdriveService.getTableSelectedFile();
      this.toggleFooterAction();
    });
  }

  toggleFooterAction(): void {
    this.isFooterDisplayed = this.selectedFiles.length > 0;
  }

  selectChip(chipIndex: number): void {
    this.chipsData.forEach((chip, index) => {
      chip.isActive = index === chipIndex ? true : false;
      chip.icon = index === chipIndex ? 'pi-check' : '';
      this.selectedChip = index === chipIndex ? chip.filter : this.selectedChip;
    });
  }

  setLabels(files: Array<File>) {
    files.forEach((file: File) => {
      if (file?.labels?.length) {
        file.labels.forEach((label: Label) => {
          //label.type = label.type.replace(/^\w/, (c) => c.toUpperCase());
          if (!this.labelsMap.has(label.type)) {
            this.labelsMap.set(label.type, label);
          }
        });
      }
    });
    this.labels = Array.from(this.labelsMap.values());
  }

  setCreators(files: Array<File>) {
    files.forEach((file: File) => {
      if (file?.datamart?.created_by?.email) {
        if (!this.creatorsMap.has(file.datamart.created_by.email)) {
          this.creatorsMap.set(
            file.datamart.created_by.email,
            file.datamart.created_by,
          );
        }
      }
      if (file?.last_modified_by?.email) {
        if (!this.creatorsMap.has(file.last_modified_by.email)) {
          this.creatorsMap.set(
            file.last_modified_by.email,
            file.last_modified_by,
          );
        }
      }
      if (file?.datamart?.contributors?.length) {
        file.datamart.contributors.forEach((contrib: People) => {
          if (!this.creatorsMap.has(contrib.email as string)) {
            this.creatorsMap.set(contrib.email as string, contrib);
          }
        });
      }
    });

    this.creators = Array.from(this.creatorsMap.values());
  }

  setTags(files: Array<File>) {
    files.forEach((file: File) => {
      if (file?.tags?.length) {
        file.tags.forEach((tag: Tag) => {
          tag.label = tag.label.replace(/^\w/, (c) => c.toUpperCase());
          if (
            !this.tags.find(
              (Tag: Tag) => Tag.label === tag.label,
            )
          ) {
            this.tags.push(tag);
          }
        });
      }
    });
  }

  setFormats(files: Array<File>) {
    files.forEach((file: File) => {
      if (file?.mime_type && file.mime_type !== this.googleFolderMimeType) {
        if (!this.formatsMap.has(file.mime_type)) {
          this.formatsMap.set(file.mime_type, file);
        }
      }
    });
    this.formats = Array.from(this.formatsMap.values());
  }

  getShortFormat(format: string): string {
    const lastOfDot = format.split('.').pop();
    const lastOfSlash = format.split('/').pop();

    // Return the smaller non-empty string
    if (
      lastOfDot?.length !== 0 &&
      (lastOfDot as string)?.length < (lastOfSlash as string)?.length
    )
      return (lastOfDot as string).replace(/^\w/, (c) => c.toUpperCase());
    return (lastOfSlash as string).replace(/^\w/, (c) => c.toUpperCase());
  }

  applyFilters() {
    this.files = this.allFiles.filter((file: File) => {
      if (
        !this.selectedCreators?.length &&
        !this.selectedLabels?.length &&
        !this.selectedFormats?.length &&
        !this.selectedTags?.length
      )
        return true;
      else if (
        this.hasCreator(file) &&
        this.hasLabel(file) &&
        this.hasFormat(file) &&
        this.hasTag(file)
      )
        return true;
      return false;
    }) as PrimeFileNode[];
  }

  hasCreator(file: File): boolean {
    if (this.selectedCreators?.length === 0) return true;
    // Loop over the creators and check if the file has the creator
    for (const creator of this.selectedCreators) {
      if (
        file?.datamart?.created_by?.email === creator?.email ||
        file?.last_modified_by?.email === creator?.email ||
        file?.datamart?.contributors?.find((contrib: People) => {
          return contrib?.email === creator?.email;
        })
      )
        return true;
    }
    return false;
  }

  hasLabel(file: File): boolean {
    if (this.selectedLabels?.length === 0) return true;
    // Loop over the labels and check if the file has the label
    for (const label of this.selectedLabels) {
      if (
        file?.labels?.find(
          (fileLabel: Label) => fileLabel?.type === label?.type,
        )
      )
        return true;
    }
    return false;
  }

  hasFormat(file: File): boolean {
    if (this.selectedFormats?.length === 0) return true;
    // Loop over the formats and check if the file has the format
    for (const format of this.selectedFormats) {
      if (file?.mime_type === format?.mime_type) return true;
    }
    return false;
  }

  hasTag(file: File): boolean {
    if (this.selectedTags?.length === 0) return true;
    // Loop over the tags and check if the file has the tag
    for (const tag of this.selectedTags) {
      if (file?.tags?.find((fileTag: Tag) => fileTag?.label === tag?.label))
        return true;
    }
    return false;
  }
}
